
.page {
    background-color:#FFFFFF;
    height: 100vh
}

.privacy-title {
    color:black;
    padding-left: 20px;
}

.privacy-sub-heading {
    color:black;
    padding-left: 40px;
    padding-right: 40px;
}

.privacy-body {
    color:black;
    padding-left: 40px;
    padding-right: 40px;
}