.logo {
    height: 100px;
    width: 100px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  
  .header {
    display: flex;
    flex-direction: 'row';
    align-items: center;
    background-color:#FFFFFF;
    
  }
  
  .header-button {
    background-color: white;
    border-width: 0px;
  }

  .header-button-image {
    width: 50px;
    height: 50px;
  }
  
  .hover-header-button {
    background-color: lightgray;
    color: #002D84;
    font-size: 20px;
    border-width: 0px;
    
  }
  
  .header-div-1 {
    display: flex;
    flex-direction: 'row';
    align-items: center;
    flex: 1;  
  }
  
  .header-div-2 {
    display: flex;
    flex-direction: 'row';
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
  
  .apple-button {
    border-radius: 13px; 
    width: 181px;
    height: 60px;
    padding-right: 10px;
  }
  

  .google-button {

    width: 207px;
    height: 80px;
    padding-right: 10px;
  }


  .install-button {
    color: white;
    font-weight: 500;
  }