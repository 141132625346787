
.page {
    background-color:#FFFFFF;
    height: 100vh
}

.why-title {
    color: black;
    text-align: center;
}

.why-sub-heading {
    color: black;
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;

}

.why-body {
    color: black;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}