.body {
    display: flex;
    background-color:#FFFF;
    flex-direction: column;
    justify-content: 'center';
    flex: 1;
    margin: 0;
  }
  
  .screenshots {
    height: 512px;
    width: 277px;
    resize: contain;
  }
  

  .screenshot-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 1.5%;
  }

  .scrollable-images-div {
    display:flex;
    flex: 1; 
    flex-direction: row; 
    justify-content: space-between;
    overflow-y: scroll;
  }