
.page {
    background-color:#FFFFFF;
    height: 100vh
}


.contact-title {
    color:black;
    padding-left: 1.5%;
}


.sub-heading-text {
    color: black;
    padding-left: 2%;
}

.body-text {
    color: black;
    padding-left: 2.5%;
}